import React from 'react';
import { SearchBox } from '@appbaseio/reactivesearch';


function SearchBar() {

    return (
        <SearchBox
            componentId="Search"
            placeholder="Search by innovation, science, or a diplomacy topic"
            style={{ width: 500, marginRight: 'auto' }}
            autosuggest={true}
            debounce={100}
            showVoiceSearch={true}
            dataField={[
                {
                  field: 'officialTitle',
                  weight: 3,
              },
              {
                  field: 'officialTitle.autosuggest',
                  weight: 1,
              },
              {
                  field: 'description',
                  weight: 3,
              },
              {
                  field: 'description.autosuggest',
                  weight: 1,
              },
              {
                  field: 'docId',
                  weight: 3,
              },
              {
                  field: 'docId.autosuggest',
                  weight: 1,
              }
              ]}
            highlight
            URLParams
            
        />
    );
};

export default SearchBar;
