// DisplayFunctions.js

import { Tag } from 'antd';
import { Tabs } from 'antd';
import { appUrl } from '../utils';

const { TabPane } = Tabs;

function DocDisplay ({ item, similarDocuments, page, useCaseName }) {

  function displayContent(content, label) {
    if (content) {
      const highlightedContent = { __html: content };
      return (
        <div style={{ display: 'inline-block' }}>
          <span><strong>{label}</strong></span>
          <div style={{ display: 'inline-block' }} dangerouslySetInnerHTML={highlightedContent}></div>
        </div>
      );
    } else {
      return null;
    }
  }

  function displayEntities(items, entityName, entityColor = '') {
      
      if (Array.isArray(items)) {
        return (
          <div>
            {items.length > 0 ? (
              <>
                {entityName}: {items.map((item, index) => (
                  <Tag color={entityColor} key={index}>
                    {item}
                  </Tag>
                ))}
              </>
            ) : null}
          </div>
        );
      } else if (typeof items === 'string' || typeof items === 'number') {
        return (
          <div>
            {entityName}: <Tag color={entityColor}>{items}</Tag>
          </div>
        );
      } else {
        return null;
      }
  }

  function displayDOI(doi, entityName, entityColor = 'cyan') {
    if (!doi) {
      return null;
    }
  
    const doiUrl = `${doi}`;
  
    return (
      <div>
        Link: <Tag color = {entityColor}>
          <a href={doiUrl}>
            {doi}
          </a>
        </Tag>
      </div>
    );
  }
  

  function displayEligibilityCriteria(content, label) {
    if (content) {
      const criteriaArray = content.split('\n'); // Split the content by newline characters
      return (
        <div>
          <h3>{label}</h3> {/* Render the label as a title */}
          <ul>
            {criteriaArray.map((criteria, index) => {
              if (criteria.trim().startsWith('Inclusion Criteria:') || criteria.trim().startsWith('Exclusion Criteria:')) {
                return <h4 key={index}>{criteria}</h4>;// Display criteria with label and no bullet
              } else {
                const trimmedCriteria = criteria.replace(/^\* /, '').trim(); // Remove leading * and trim whitespace
                return trimmedCriteria ? <li key={index}>{trimmedCriteria}</li> : null; // Check if criteria is not empty
              }
            })}
          </ul>
        </div>
      );
    } else {
      return null;
    }
  }
  function displayRecommendations(recommendations) {
    if (!Array.isArray(recommendations) || recommendations.length === 0) {
      return <p>No recommendations reported</p>;
    }
  
    return (
      <ol>
        {recommendations.map((rec, index) => (
          <li key={index}>{rec}</li>
        ))}
      </ol>
    );
  }
  function displayFindings(findings) {
    if (!Array.isArray(findings) || findings.length === 0) {
      return <p>No key findings reported</p>;
    }
  
    return (
      <ol>
        {findings.map((rec, index) => (
          <li key={index}>{rec}</li>
        ))}
      </ol>
    );
  }
  
    
  function displayLocations(locations) {
      return (
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          {locations && locations.map((location, index) => (
              <div key={index} style={{ flex: 'calc(33.33% - 20px)', marginBottom: '20px' }}>
              
              {location.contacts && location.contacts.map((contact, contactIndex) => (
                  contact.name && 
                  <div key={contactIndex} style={{ fontWeight: 'bold'}}>{contact.name}</div>
              ))}

              {location.facility && <div >{location.facility}</div>}
              <div >
              {`${location.city || ''}${location.city && location.country ? ', ' : ''}${location.country || ''}${(location.city || location.country) && location.zip ? ', ' : ''}${location.zip || ''}`}
              </div>
              
              </div>
          ))}
          </div>
      );
  }
    
  function displayInterventions(interventions) {
      return (
        <div>
          {interventions && interventions.map((intervention, index) => (
            <div key={index} style={{ marginBottom: '20px' }}>
              {intervention.interventionNames && intervention.interventionNames.length > 0 && (
                <div>
                  <div style={{ fontWeight: 'bold'}}>
                    Intervention
                  </div>
                  <div>
                    {intervention.interventionNames.join(', ')}
                  </div>
                  {intervention.description && (
                    <div>
                      Description: {intervention.description}
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      );
  }
    
  function displayOutcomes(outcomes, label) {
      if (!Array.isArray(outcomes) || outcomes.length === 0) {
        return null;
      }
    
      return (
        <div style={{paddingBottom:'10px' }}>
          <div style={{ fontWeight: 'bold' }}>{label}</div>
          <ol>
            {outcomes.map((outcome, index) => (
              <li key={index}>{outcome}</li>
            ))}
          </ol>
        </div>
      );
  }
    
  function displayCitations(citations) {
      if (!Array.isArray(citations) || citations.length === 0) {
        return null;
      }
    
      return (
        <ol>
          {citations.map((citation, index) => (
            <li key={index}>
              {citation.citation}
              <a href={`https://pubmed.ncbi.nlm.nih.gov/${citation.pmid}`} target="_blank" rel="noopener noreferrer">
                (Link)
              </a>
            </li>
          ))}
        </ol>
      );
  }

  function displaySimilarDocuments(similarDocuments, docId, useCaseName) {
    if (!Array.isArray(similarDocuments) || similarDocuments.length === 0) {
      return {
        count: 0,
        documents: <p>No similar documents found.</p>
      };
    }
    
    const count = similarDocuments.length;
  
    const documents = similarDocuments.map((doc, index) => (
      <div key={index}>
        <h3>{doc._source.title}</h3>
        <p dangerouslySetInnerHTML={{ __html: doc._source.description }} />
        <a href={`${appUrl}/${useCaseName}/${docId}`} target="_blank" rel="noopener noreferrer">
          View Document
        </a>
      </div>
    ));
  
    return {
      count: count,
      documents: (
        <div>
          <p style={{ textAlign: 'right', color: '#1677ff', fontStyle: 'italic' }}>{count} similar documents found</p>
          {documents}
        </div>
      )
    };
  }
  return page === "DocDetails" ? (
    <Tabs defaultActiveKey="1" tabPosition="left">
      <TabPane tab="Entities" key="1">
        
        {displayEntities(item.authors, 'Authors', 'geekblue')}
        {displayEntities(item.interventionsType, 'Interventions Type', 'cyan')}
        {displayEntities(item.leadSponsor, 'Lead Sponsor', 'orange')}
        {displayEntities(item.collaborators, 'Collaborators', 'orange')}
        {displayEntities(item.overallStatus, 'Overall Status', 'magenta')}
        {displayEntities(item.stdAges, 'Age Group', 'blue')}
      </TabPane>
      <TabPane tab="Overview" key="2" >
        <div style={{ display: 'flex' }}>
          <div style={{ flex: '40%' }}>
            {displayEntities(item.studyStartDate, 'Study Start Date','geekblue')}
            {displayEntities(item.primaryCompletionDate, 'Primary Completion Date','geekblue')}
            {displayEntities(item.studyCompletionDate, 'Study Completion Date','geekblue')}
            {displayEntities(item.enrollmentCount, 'Enrollment Count','orange')}
            {displayEntities(item.studyType, 'Study Type','orange')}
            {displayEntities(item.phases, 'Phase','magenta')}
            {displayEntities(item.conditions, 'Conditions', 'geekblue')}
            {displayEntities(item.interventionsType, 'Interventions Type', 'cyan')}
            {displayEntities(item.interventions, 'Interventions', 'cyan')}
            {displayEntities(item.orgStudyId, 'Other Study ID Numbers','orange')}
            {displayEntities(item.keywords, 'Keywords','geekblue')}
          </div>
          <div style={{ flex: '60%' }}>
            {displayContent(item.detailedDescription, 'Detailed Description')}
          </div>
        </div>
      </TabPane>
      <TabPane tab="Participation" key="3">
        <div style={{ display: 'flex' }}>
          <div style={{ flex: '35%' }}>
            {displayEntities(item.minimumAge, 'Min. Age Eligible for Study','orange')}
            {displayEntities(item.stdAges, 'Ages Eligible for Study','geekblue')}
            {displayEntities(item.sex, 'Sexes Eligible for Study','orange')}
            {displayEntities(item.healthyVolunteers, 'Accepts Healthy Volunteers','geekblue')}
          </div>
          <div style={{ flex: '65%' }}>
            <span style={{ fontWeight: 'bold', paddingBottom: '10px' }}>Eligibility Criteria </span>
            {displayEligibilityCriteria(item.eligibilityCriteria, '')}
          </div>
        </div>
      </TabPane>
      <TabPane tab="Study Plan" key="4">
        <div style={{ display: 'flex' }}>
          <div style={{ flex: '25%' }}>
            <span style={{ fontWeight: 'bold', paddingBottom: '10px' }}>Design Details</span>
            {displayEntities(item.designPrimaryPurpose, 'Primary Purpose','cyan')}
            {displayEntities(item.designAllocation, 'Allocation','geekblue')}
            {displayEntities(item.designInterventionModel, 'Interventional Model','magenta')}
            {displayEntities(item.designObservationalModel, 'Observational Model','geekblue')}
            {displayEntities(item.samplingMethod, 'Sampling Method','orange')}
            {displayEntities(item.designMasking, 'Masking','orange')}
          </div>
          <div style={{ flex: '75%' }}>
            {displayInterventions(item.armGroups)}
            {displayOutcomes(item.primaryOutcomes, 'Primary Outcomes')}
            {displayOutcomes(item.secondaryOutcomes, 'Secondary Outcomes')}
          </div>
        </div>
        
      </TabPane>
      <TabPane tab="Sponsors & Collaborators" key="5">
        {displayEntities(item.leadSponsor, 'Lead Sponsor', 'geekblue')}
        {displayEntities(item.collaborators, 'Collaborators', 'orange')}
      </TabPane>
      {item.publications && (
      <TabPane tab="Publications" key="6">
        {displayCitations(item.publications)}
      </TabPane>)}
      <TabPane tab="Key Dates" key="7">
        {displayEntities(item.studyFirstSubmitDate, 'First Submitted', 'geekblue')}
        {displayEntities(item.studyFirstSubmitQcDate, 'First Submitted that Met QC Criteria', 'cyan')}
        {displayEntities(item.studyFirstPostDateStruct, 'First Posted (Estimated)', 'orange')}
        {displayEntities(item.resultsFirstSubmitDate, 'Results First Submitted', 'orange')}
        {displayEntities(item.resultsFirstSubmitQcDate, 'Results First Submitted that Met QC Criteria', 'magenta')}
        {displayEntities(item.resultsFirstPostDateStruct, 'Results First Posted', 'blue')}
        {displayEntities(item.lastUpdateSubmitDate, 'Last Update Submitted that met QC Criteria', 'geekblue')}
        {displayEntities(item.lastUpdatePostDateStruct, 'Last Update Posted', 'cyan')}
        {displayEntities(item.lastVerifiedDate, 'Last Verified', 'orange')}
      </TabPane>
      {item.hasResults === true && (<TabPane tab="Results" key="8"></TabPane>)}
      {item.locations && (<TabPane tab="Contacts & Locations" key="9">
        {displayLocations(item.locations)}
      </TabPane>)}    
      <TabPane tab={`Similar Documents  (${displaySimilarDocuments(similarDocuments, item.docId, useCaseName).count})`} key="10">
          {displaySimilarDocuments(similarDocuments, item.docId, useCaseName).documents}
      </TabPane>
    </Tabs>
) : (
    <Tabs defaultActiveKey="1" tabPosition="top" className={"moreinfo-tab"}>
      <TabPane tab="Entities" key="1" className={"moreinfo-tabpane"}>
        {console.log('Authors:', item.authors)}
        {displayEntities(item.authors, 'Authors', 'geekblue')}
        {displayEntities(item.tags, 'Tags', 'cyan')}
        {displayEntities(item.type, 'Publication Type', 'orange')}
        {displayEntities(item.pubDate, 'Publication Date', 'blue')}
        {displayEntities(item.entityLoc, 'Locations', 'magenta')}
        {displayEntities(item.entityOrg, 'Organizations', 'blue')}
        
      </TabPane>
      <TabPane tab="Overview" key="2" className={"moreinfo-tabpane"} >
        <div style={{ display: 'flex' }}>
          <div style={{ flex: '40%' }}>
            
            {displayEntities(item.entityEnt, 'Enterprise','orange')}
            {displayEntities(item.entityInd, 'Indicators','geekblue')}
            {displayEntities(item.entityTech, 'Technology','magenta')}
            {displayDOI(item.doi, 'DOI', 'cyan')}
          </div>
          <div style={{ flex: '60%' }}>
            {displayContent(item.detailedDescription, 'Summary')}
          </div>
        </div>
      </TabPane>
      
      <TabPane tab="Recommendations" key="3" className={"moreinfo-tabpane"}>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: '25%' }}>
            <span style={{ fontWeight: 'bold', paddingBottom: '10px' }}>Key Recommendations</span>
            {displayEntities(item.designPrimaryPurpose, 'Primary Purpose','cyan')}
            {displayEntities(item.designAllocation, 'Allocation','geekblue')}
            {displayEntities(item.designInterventionModel, 'Interventional Model','magenta')}
            {displayEntities(item.designObservationalModel, 'Observational Model','geekblue')}
            {displayEntities(item.samplingMethod, 'Sampling Method','orange')}
            {displayEntities(item.designMasking, 'Masking','orange')}
          </div>
          <div style={{ flex: '75%' }}>
            {displayRecommendations(item.recommendations)}
            {displayOutcomes(item.Recommendations, 'Primary Outcomes')}
            {displayOutcomes(item.Recommendations, 'Secondary Outcomes')}
          </div>
        </div>
        
      </TabPane>

      <TabPane tab="Findings" key="4" className={"moreinfo-tabpane"}>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: '25%' }}>
            <span style={{ fontWeight: 'bold', paddingBottom: '10px' }}>Key Findings</span>
            {displayEntities(item.designPrimaryPurpose, 'Primary Purpose','cyan')}
            {displayEntities(item.designAllocation, 'Allocation','geekblue')}
            {displayEntities(item.designInterventionModel, 'Interventional Model','magenta')}
            {displayEntities(item.designObservationalModel, 'Observational Model','geekblue')}
            {displayEntities(item.samplingMethod, 'Sampling Method','orange')}
            {displayEntities(item.designMasking, 'Masking','orange')}
          </div>
          <div style={{ flex: '75%' }}>
            {console.log('Recommendations:', item)}
            {displayFindings(item.findings)}
            {displayOutcomes(item.Recommendations, 'Primary Outcomes')}
            {displayOutcomes(item.Recommendations, 'Secondary Outcomes')}
          </div>
        </div>
        
      </TabPane>

    </Tabs>
);
}

export default DocDisplay