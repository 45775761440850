const chartData = [
    
    {
      name: 'Key Dates',
      theme: 'Publication',
      dataField: 'entityDate',
      componentId: 'SecondaryOutcomes_Chart',
      type: 'pie'
    },
    
    {
      name: 'Tags',
      theme: 'Publication',
      dataField: 'tags',
      componentId: 'Tags_Chart',
      type: 'pie'
    },
    
    {
      name: 'Authors',
      theme: 'Publication',
      dataField: 'authors',
      componentId: 'Authors_Chart',
      type: 'pie'
    },
    
    {
      name: 'Publication Year',
      theme: 'Publication',
      dataField: 'pubDate',
      componentId: 'PubDate_Chart',
      type: 'pie'
    },
    {
      name: 'Publication Type',
      theme: 'Publication',
      dataField: 'type',
      componentId: 'Type_Chart',
      type: 'pie'
    },    
    {
      name: 'Key Countries',
      theme: 'Entities',
      dataField: 'entityLoc',
      componentId: 'KeyCountries_Chart',
      type: 'pie'
    },
    
    {
      name: 'Enterprises',
      theme: 'Entities',
      dataField: 'entityEnt',
      componentId: 'Enterprises_Chart',
      type: 'pie'
    },
    {
      name: 'Key Technologies',
      theme: 'Entities',
      dataField: 'entityTech',
      componentId: 'KeyTechnologies_Chart',
      type: 'pie'
    },
    {
      name: 'Key Organizations',
      theme: 'Entities',
      dataField: 'entityOrg',
      componentId: 'KeyOrganizations_Chart',
      type: 'pie'
    },
    {
      name: 'Innovation Indicators',
      theme: 'Key Indicators',
      dataField: 'entityInd',
      componentId: 'Indicators_Chart',
      type: 'pie'
    }
  ];
  
export default chartData;
  