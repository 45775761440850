const filterData = [
  
    
    
    {
      name: 'Tags',
      type: 'multilist',
      theme: 'Filters',
      dataField: 'tags',
      componentId: 'Tags_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    {
      name: 'Authors',
      type: 'multilist',
      theme: 'Filters',
      dataField: 'authors',
      componentId: 'Authors_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    {
      name: 'Publication Type',
      type: 'multilist',
      theme: 'Filters',
      dataField: 'type',
      componentId: 'Type_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    {
      name: 'Publication Year',
      type: 'multilist',
      theme: 'Filters',
      dataField: 'pubDate',
      componentId: 'PubDate_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    {
      name: 'Key Dates',
      type: 'multilist',
      theme: 'Filters',
      dataField: 'entityDate',
      componentId: 'EntityDate_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    {
      name: 'Key Organizations',
      type: 'multilist',
      theme: 'Filters',
      dataField: 'entityOrg',
      componentId: 'EntityOrg_Filter',
      showSearch: false,
      showLoadMore: false,
    },
    {
      name: 'Key Countries',
      type: 'multilist',
      theme: 'Filters',
      dataField: 'entityLoc',
      componentId: 'EntityLoc_Filter',
      showSearch: true,
      showLoadMore: false,
    },
    {
      name: 'Key Technologies',
      type: 'multilist',
      theme: 'Filters',
      dataField: 'entityTech',
      componentId: 'EntityTech_Filter',
      showSearch: true,
      showLoadMore: false,
    },
    {
      name: 'Enterprises',
      type: 'multilist',
      theme: 'Filters',
      dataField: 'entityEnt',
      componentId: 'EntityEnt_Filter',
      showSearch: true,
      showLoadMore: false,
    },
    {
      name: 'Innovation Indicators',
      type: 'multilist',
      theme: 'Filters',
      dataField: 'entityInd',
      componentId: 'EntityInd_Filter',
      showSearch: true,
      showLoadMore: false,
    }
  ];
  
  export default filterData;
  